import React, { Suspense } from 'react'
import { Route, Routes, Navigate, HashRouter } from 'react-router-dom'
import './scss/style.scss'
import { useSelector } from 'react-redux'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/public/login/Login'))
const Page404 = React.lazy(() => import('./views/public/page404/Page404'))
const Page500 = React.lazy(() => import('./views/public/page500/Page500'))
const Form = React.lazy(() => import('./views/public/Form'))

const App = () => {
  const authed = useSelector((state) => state.authed)

  return (
    <HashRouter>
      <Suspense fallback={loading}>
        <Routes>
          <Route exact path="/login" name="Login Page" element={<Login />} />
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          <Route path="/form/:formId" name="Form" element={<Form />} />
          {authed ? (
            <Route path="*" name="Home" element={<DefaultLayout />} />
          ) : (
            <Route path="*" element={<Navigate to="/login" replace />} />
          )}
        </Routes>
      </Suspense>
    </HashRouter>
  )
}

export default App

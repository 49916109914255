import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import { Provider } from 'react-redux'
import { store, persistor } from './store'
import { PersistGate } from 'redux-persist/integration/react'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <PersistGate loading={loading} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
)
